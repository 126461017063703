import { createRoot, hydrateRoot } from 'react-dom/client';
import { initWeglot, isOnTranslatedPage } from './weglot-browser';

export const replaceHydrateFunction = () => {
    return (element, container) => {
        if(window.__neonodeIsDev) {
            console.log('** gatsby-browser.js: Dev mode - render root.');

            const root = createRoot(container);
            
            root.render(element);

            return;
        }

        if(isOnTranslatedPage()) {
            console.log('** gatsby-browser.js: Weglot-instance found, English not selected - render root.');

            const root = createRoot(container);
            
            root.render(element);
        } else {
            console.log('** gatsby-browser.js: Hydrate root.');

            hydrateRoot(container, element);
        }
    }
}

export const onClientEntry = () => {
    initWeglot();
}

export const onInitialClientRender = () => {
    if(isOnTranslatedPage()) {
        const gatsbyEl = document.querySelector('#___gatsby');

        if(gatsbyEl) {
            console.log('** gatsby-browser.js: Adding data attribute for Weglot Dynamic element selector.');

            setTimeout(() => {
                gatsbyEl.dataset.weglotDynamicContent = 'true';
            }, 1000);
        }
    }
}

export default {};